import { createStore } from '@stencil/store';

const { state } = createStore({
  app: {},
  browser: {},
  auth: {},
  Router: {},
  feedData: {
    requested: new Date(),
  },
  cached: {
    key: "howardson-dealer-portal",
    tk: "arcane-roots-38625f02-2c2a-415e-afa7-c718520b7bb8",
    expHours: 3,
  },
  areas: [],
});

// onChange("auth", (value) => {
//   console.log("Auth Updated", value);
// });

export default state;
